import React, { FC } from 'react';
import RTGameCover from '../../components/game/cover/game-cover';
import RTTournamentsType from '../../components/game/tournaments-types/tournaments-types';
import RTFaq from '../../components/global/faq/faq';
import RTLayout from '../../components/global/layout/layout';
import SEO from '../../components/global/seo/seo';
import { Game } from '../../interfaces/game.interface';

type RTGameProps = {
  pageContext: Game;
};

const RTGame: FC<RTGameProps> = ({ pageContext }) => {
  const game = pageContext;
  const { tournamentTypes } = pageContext;

  return (
    <RTLayout
      menuText={pageContext?.uiElements?.menuTxt[0]}
      footerText={pageContext?.uiElements?.footerTxt[0]}
      langModalTranslations={{ title: pageContext?.uiElements?.languageModalTitle, subtitle: pageContext?.uiElements?.languageModalText }}
      cookie={{
        cookieContent: pageContext?.uiElements.cookieContent,
        cookieButtonAccept: pageContext?.uiElements.cookieButtonAccept,
        cookieButtonReject: pageContext?.uiElements.cookieButtonReject,
      }}
    >
      <SEO title={game.title} description={game.description} image={game.cover[0].url} />
      <RTGameCover {...game} />
      <div
        style={{
          backgroundColor: '#fff',
          backgroundSize: game.isImageCover ? `cover` : 'contain',
          zIndex: 1,
          position: 'relative',
        }}
      >
        {tournamentTypes && tournamentTypes.length > 0 && (
          <RTTournamentsType
            items={tournamentTypes}
            mainTournamentColor={game.tournamentMainColor}
            secondaryTournamentColor={game.tournamentSecondaryColor}
            gameMainColor={game.mainColor}
            gameCoverColor={game.gameCoverColor}
            guidelinesPopInQuestion={game.guidelinesPopInQuestion}
            guidelinesPopInButtonYes={game.guidelinesPopInButtonYes}
            guidelinesPopInButtonNo={game.guidelinesPopInButtonNo}
            tournamentOddColor={game.tournamentOddColor}
            tournamentEvenColor={game.tournamentEvenColor}
            tournamentOddImage={game.tournamentOddImage && game.tournamentOddImage[0] ? game.tournamentOddImage[0].url : ''}
            tournamentEvenImage={game.tournamentEvenImage && game.tournamentEvenImage[0] ? game.tournamentEvenImage[0].url : ''}
            uiElements={game.uiElements}
            bgImage={`url('${
              game.tournamentFaqBackgroundImage && Array.isArray(game.tournamentFaqBackgroundImage)
                ? game?.tournamentFaqBackgroundImage[0]?.url
                : ''
            }')`}
            isImageCover={game.isImageCover}
            textColor={game.textColor}
            gameCodeName={game.gameCodeName}
          />
        )}
        {game.questions && game.questions.length > 0 && (
          <RTFaq
            items={game.questions}
            faqImage={game.faqImage}
            faqTitle='FAQ'
            mainColor={game.tournamentMainColor}
            bgImage={`url('${
              game.tournamentFaqBackgroundImage && Array.isArray(game.tournamentFaqBackgroundImage)
                ? game?.tournamentFaqBackgroundImage[0]?.url
                : ''
            }')`}
            textColor={game.textColor}
          />
        )}
      </div>
    </RTLayout>
  );
};

export default RTGame;
