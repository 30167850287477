import React, { FC, useEffect, useState } from 'react';
import { Game } from '../../../interfaces/game.interface';
import { getContrastYIQ } from '../../../utils';
import GlobalButton from '../../global/global-button/global-button';
import * as styles from './game-cover.module.scss';

const RTGameCover: FC<Game> = game => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 300);
  }, []);

  return (
    <section className='relative z-10' style={{ color: game.gameHeaderColor }}>
      <div className='flex absolute inset-0 overflow-hidden'>
        <div className={`w-full ${styles.leftContainer}`}>
          <div className={styles.backgroundImage} style={game.gameHeroTexture.length === 0 ? { backgroundColor: game.gameCoverColor } : {}}>
            {game.gameHeroTexture.length > 0 && game.gameHeroTexture[0].url && <img src={game.gameHeroTexture[0].url} alt='' />}
          </div>
        </div>

        <div className={`absolute h-full z-10 right-0 lg:w-4/12 ${styles.rightContainer}`}>
          <div className={`opacity-70 h-full`}>
            <img src={game.cover[0].url} alt={game.title} className='object-cover h-full w-full' />
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='relative z-10 pt-52 pb-40 lg:pb-36 max-w-xl'>
          <h1 className={styles.title}>
            {game.title}
            <span className={styles.decorator} style={{ backgroundColor: game.mainColor }} />
          </h1>
          <div
            className={`${styles.description} wysiwyg`}
            dangerouslySetInnerHTML={{
              __html: game.description,
            }}
          />
          <GlobalButton
            animated={!isVisible}
            url='guidelines'
            text={game.uiElements.buttonsTxt[0].guidelineButtons || 'Guidelines'}
            bgColor={game.mainColor}
            txtColor={getContrastYIQ(game.mainColor)}
          />
        </div>
      </div>
    </section>
  );
};

export default RTGameCover;
