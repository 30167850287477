import Dialog from '@material-ui/core/Dialog';
import React, { FC, useEffect, useState } from 'react';
import { InView } from 'react-intersection-observer';
import Close from '../../../assets/icon/Close';
import { TournamentType } from '../../../interfaces/tournaments.interface';
import UiElements from '../../../interfaces/ui-elements.interface';
import { getContrastYIQ } from '../../../utils';
import GlobalButton from '../../global/global-button/global-button';
import { getLangFullPathname } from '../../global/languages/languageUtils';
import * as styles from './tournaments-types.module.scss';

type RTTournamentsTypeProps = {
  items: TournamentType[];
  mainTournamentColor: string;
  secondaryTournamentColor: string;
  textColor: string;
  gameMainColor: string;
  gameCoverColor: string;
  bgImage: string;
  isImageCover: boolean;
  guidelinesPopInQuestion: string;
  guidelinesPopInButtonYes: string;
  guidelinesPopInButtonNo: string;
  gameCodeName: string;
  tournamentOddColor?: string;
  tournamentEvenColor?: string;
  tournamentOddImage?: string;
  tournamentEvenImage?: string;
  uiElements: UiElements;
};

const RTTournamentsType: FC<RTTournamentsTypeProps> = ({
  items,
  mainTournamentColor,
  secondaryTournamentColor,
  textColor,
  gameMainColor,
  gameCoverColor,
  bgImage,
  isImageCover,
  guidelinesPopInQuestion,
  guidelinesPopInButtonYes,
  guidelinesPopInButtonNo,
  gameCodeName,
  tournamentOddColor,
  tournamentEvenColor,
  tournamentOddImage,
  tournamentEvenImage,
  uiElements,
}) => {
  const [open, setOpen] = useState(false);
  const [currentLang, setCurrentLang] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const currentLang = localStorage.getItem('RT_current_lang');

    if (currentLang) {
      setCurrentLang((JSON.parse(currentLang) as { value: string }).value);
    }
  }, []);

  const getEvenOrOddImage = (index: number) => {
    return index % 2 === 0 ? tournamentOddImage || '' : tournamentEvenImage || '';
  };

  const getEvenOrOddBgColor = (index: number) => {
    return index % 2 === 0 ? tournamentOddColor || '#ffffff' : tournamentEvenColor || '#ffffff';
  };

  return (
    <section>
      <Dialog className='tournaments-dialog' open={open} onClose={handleClose}>
        <div className='p-16' style={{ backgroundImage: bgImage, backgroundSize: isImageCover ? `cover` : 'contain', height: '100%' }}>
          <button className='absolute top-8 right-8' onClick={handleClose}>
            <Close stroke={secondaryTournamentColor} height={24} width={24} />
          </button>
          <h3 className='text-center text-3xl mb-8'>{guidelinesPopInQuestion}</h3>
          <div className='md:flex'>
            <div className='px-8 py-4' onClick={handleClose}>
              <GlobalButton url='guidelines' bgColor={secondaryTournamentColor} txtColor={gameCoverColor} text={guidelinesPopInButtonNo} />
            </div>
            <div className='px-8 py-4' onClick={handleClose}>
              <GlobalButton
                url={getLangFullPathname(currentLang, `/tournament-form?game=${gameCodeName}`, true, currentLang !== '')}
                bgColor={mainTournamentColor}
                text={guidelinesPopInButtonYes}
              />
            </div>
          </div>
        </div>
      </Dialog>
      {items.map((tournament, index) => (
        <InView
          threshold={0.4}
          as='div'
          key={index + 'TT' + tournament.id}
          className={`${styles.ttContainer} relative transition-opacity duration-500 opacity-0 bg-cover bg-center bg-no-repeat`}
          onChange={(inView, entry) => (inView ? entry.target.classList.add('opacity-100') : '')}
          style={
            index === 0
              ? {
                  ['--background-color' as string]: secondaryTournamentColor,
                  backgroundColor: getEvenOrOddBgColor(index),
                  backgroundImage: `url(${getEvenOrOddImage(index)})`,
                }
              : {
                  backgroundColor: getEvenOrOddBgColor(index),
                  backgroundImage: `url(${getEvenOrOddImage(index)})`,
                }
          }
        >
          <div className='container'>
            <div className='max-w-screen-xl mx-auto'>
              <div className={`flex justify-between ${index % 2 === 0 ? 'col-reverse' : 'flex-row-reverse'}`}>
                <div
                  className='flex w-full flex-col justify-center pb-8 md:pb-12 lg:py-16 lg:w-7/12'
                  style={{ color: getContrastYIQ(getEvenOrOddBgColor(index)) }}
                >
                  <h3
                    style={{
                      ['--background-color' as string]: secondaryTournamentColor,
                      ['--color' as string]: getContrastYIQ(getEvenOrOddBgColor(index)),
                    }}
                    className={`${styles.categoryTitle} text-xl md:text-2xl lg:text-4xl mb-12 flex justify-between md:inline-block`}
                  >
                    <span className='w-7/12'>{tournament.category}</span>
                    {tournament.tournamentImage?.length > 0 && (
                      <div className='relative w-5/12 md:w-4/12 lg:hidden'>
                        <div className='absolute -top-5 md:-top-9 w-full'>
                          <img src={tournament?.tournamentImage[0].url} alt='' className='object-contain' />
                        </div>
                      </div>
                    )}
                  </h3>
                  <h3 className='text-xl mb-4 font-bold'>{uiElements.conditionsTitle || 'Conditions'}</h3>
                  <div className={`space-y-3 mb-10`}>
                    {tournament.conditions.map((conditions, index) => (
                      <p key={index}>{conditions.condition}</p>
                    ))}
                  </div>
                  <div onClick={handleClickOpen}>
                    <GlobalButton
                      url={tournament.title}
                      txtColor={getContrastYIQ(mainTournamentColor)}
                      bgColor={mainTournamentColor}
                      text={uiElements.buttonsTxt[0].licenceRequest || 'License request'}
                    />
                  </div>
                </div>
                <div className={`hidden lg:flex items-center relative lg:w-5/12 ${index % 2 === 0 ? 'lg:ml-12' : 'lg:mr-12'}`}>
                  <div
                    className={`absolute z-0 w-screen h-full ${index % 2 === 0 ? 'left-0' : 'right-0'}`}
                    style={{
                      backgroundColor: secondaryTournamentColor,
                      clipPath: index % 2 === 0 ? 'polygon(10% 0%, 100% 0, 100% 100%, 0% 100%)' : 'polygon(0 0, 90% 0, 100% 100%, 0% 100%)',
                    }}
                  />
                  <div
                    className='relative z-10 w-full'
                    style={{ animation: `floating-animation 5s infinite alternate`, animationDelay: `${index}s` }}
                  >
                    {tournament.tournamentImage?.length > 0 && (
                      <img src={tournament?.tournamentImage[0].url} alt='' className='object-contain w-full h-full' />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </InView>
      ))}
    </section>
  );
};

export default RTTournamentsType;
